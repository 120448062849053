import React, { memo } from 'react'

import { DropdownIndicator, customStyles } from '@ReactSelectStyles'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import Select from 'react-select'
import { Label } from 'reactstrap'

function SelectField({
  wd,
  data,
  label,
  header,
  search,
  disabled,
  required,
  helpIcon,
  menuHeight,
  placeholder,
  formikError,
  defaultWidth,
  labelClassName,
  controlMinWidth,
  controlMaxWidth,
  containerZIndex,
  labelExtraClasses,
  formikErrorText,
  ...rest
}) {
  return (
    <>
      {label && (
        <div className="d-flex align-items-center form-label">
          {required && <div className="required-dot" />}
          <Label
            className={classNames({
              [labelClassName]: labelClassName,
              [labelExtraClasses]: labelExtraClasses,
              'mb-0 fs-small pl-10px': true
            })}
          >
            {label}
            {helpIcon}
          </Label>
        </div>
      )}

      <Select
        // menuIsOpen
        options={data}
        placeholder={placeholder}
        components={{
          IndicatorSeparator: false,
          DropdownIndicator
        }}
        isSearchable={search}
        isDisabled={disabled}
        getOptionLabel={(e) => (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {e.icon}
            <span style={{ marginLeft: 5 }}>{e.text || e.label}</span>
          </div>
        )}
        styles={customStyles(
          header,
          wd,
          menuHeight,
          controlMinWidth,
          controlMaxWidth,
          containerZIndex,
          defaultWidth,
          formikError
        )}
        {...rest}
      />
      {formikError && (
        <div className="text-danger font-small-2 mt--10 pl-10px">
          {formikErrorText}
        </div>
      )}
    </>
  )
}

SelectField.propTypes = {
  wd: PropTypes.any,
  menuHeight: PropTypes.string,
  data: PropTypes.array,
  change: PropTypes.func,
  header: PropTypes.bool,
  search: PropTypes.bool,
  value: PropTypes.object,
  label: PropTypes.string,
  labelClassName: PropTypes.string,
  labelExtraClasses: PropTypes.string,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  helpIcon: PropTypes.element,
  controlMinWidth: PropTypes.string,
  controlMaxWidth: PropTypes.string,
  formikErrorText: PropTypes.string
}

export default memo(SelectField)
