export const columns = [
  {
    header: 'Invoice Number'
  },
  {
    header: 'Provider'
  },
  {
    header: 'Total'
  }
]
