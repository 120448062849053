import { createApi } from '@reduxjs/toolkit/query/react'
import moment from 'moment'
import { baseQuery } from '../common'

export const calendarApi = createApi({
  reducerPath: 'calendarApi',
  baseQuery,
  tagTypes: ['CalendarBookings'],

  endpoints: (builder) => ({
    getBookings: builder.query({
      query: ({
        offset = 0,
        limit = 100000,
        startDate,
        endDate,
        location,
        room,
        view
      }) => {
        let endpoint = `/booking-list?ordering=created_at&offset=${offset}&limit=${limit}`

        if (startDate) {
          const start = moment(startDate).format('YYYY-MM-DD')
          endpoint += `&start_date=${start}`
        }
        if (endDate) {
          const end = moment(endDate).format('YYYY-MM-DD')
          endpoint += `&end_date=${end}`
        }

        if (location) {
          endpoint += `&location=${location}`
        }

        if (room) {
          endpoint += `&room=${room}`
        }
        return { url: endpoint, method: 'GET' }
      },
      providesTags: ['CalendarBookings']
    })
  })
})

export const { usePrefetch, useGetBookingsQuery } = calendarApi
