import { toastContent } from '../../../utility/Utils'
import { PROVIDERS_PARAMS } from '../../../views/providers/constants'
import { providersApi } from '../providersApi'

export const transformProivderResponse = (data) => {
  return {
    ...data,
    first_name: data?.first_name || '',
    last_name: data?.last_name || '',
    middle_name: data?.middle_name || '',
    suffix: data?.suffix || '',
    practice: data?.practice || '',
    email: data?.email || '',
    phone_number: data?.phone_number || '',
    provider_license: data?.provider_license || [],
    npi: data?.npi || '',
    npi_two: data?.npi_two || '',
    tax: data?.npi || '',
    tax_two: data?.npi_two || '',
    taxonomy: data?.taxonomy || '',
    caqh: data?.caqh || '',
    avatar: data?.avatar || '',
    status: data?.status || ''
  }
}
export const formDataToDataURL = (formData) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()

    reader.onload = function (event) {
      const dataURL = event.target.result
      resolve(dataURL)
    }

    const file = formData.get('image') // Replace 'image' with the name of your file input field
    if (!file) {
      reject(new Error('No file found in FormData.'))
    } else {
      reader.readAsDataURL(file)
    }
  })
}
// Helper function to update query data
export const updateProvidersQueryData = (dispatch, patchFn) => {
  const providersParams = JSON.parse(localStorage.getItem(PROVIDERS_PARAMS))
  return dispatch(
    providersApi.util.updateQueryData('getProviders', providersParams, patchFn)
  )
}
export const updateProviderQueryDataHelper = (dispatch, id, patchFn) => {
  const patchResult = dispatch(
    providersApi.util.updateQueryData('getProvider', id, patchFn)
  )
  const updateProviders = updateProvidersQueryData(dispatch, (draft) => {
    draft?.result?.forEach((item, index) => {
      if (item.id === id) {
        patchFn(draft.result[index])
      }
    })
  })

  return { patchResult, updateProviders }
}

// Helper function to handle query error

export const handleProviderQueryError = (
  undoFn,
  name = 'Provider Updated Successfully',
  msg
) => {
  return (queryFulfilled) => {
    queryFulfilled.catch(undoFn)
    queryFulfilled.then(() => {
      toastContent({ name, color: 'success', msg })
    })
  }
}
export const getCreditDetails = (data, promo) => {
  const isAdding = data?.status === 1
  const creditKey = promo ? 'promo_credit' : 'ethera_credit'
  const title = promo ? 'Promo' : 'Ethera'
  const action = isAdding ? 'Add' : 'Remove'
  const symbol = isAdding ? '+' : '-'
  const name = `${action} ${title} Credit`
  const msg = `${title} credit has been ${action.toLowerCase()}ed Successfully`

  return {
    creditKey,
    name,
    msg,
    symbol
  }
}
