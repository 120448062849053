import { createSlice } from '@reduxjs/toolkit'
import { getCurrentWeekDates } from '../../views/calendar/utils'
import moment from 'moment'
const { startDate, endDate } = getCurrentWeekDates()

export const CalendarReducer = createSlice({
  name: 'calendar',
  initialState: {
    dateSet: {
      view: 'timeGridWeek',
      startDate,
      endDate
    },

    room: null,
    location: null
  },
  reducers: {
    updateRoom: (state, action) => {
      state.room = action.payload
    },
    updateDateSet: (state, action) => {
      state.dateSet = action.payload
    },
    updateView: (state, action) => {
      state.dateSet.view = action.payload.view
    },

    updateLocation: (state, action) => {
      state.location = action.payload
      state.room = null
    }
  }
})
export const { updateDateSet, updateLocation, updateRoom, updateView } =
  CalendarReducer.actions
export default CalendarReducer.reducer
