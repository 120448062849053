import { createSlice } from '@reduxjs/toolkit'

import { getCurrentTimeAction } from './getCurrentTimeAction'

const CurrentTimeReducer = createSlice({
  name: 'currentTime',
  initialState: {
    currentTime: null,
    loading: false
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      // ** Get all activities
      .addCase(getCurrentTimeAction.pending, (state) => {
        state.loading = true
      })
      .addCase(getCurrentTimeAction.fulfilled, (state, action) => {
        state.currentTime = action.payload
      })
      .addCase(getCurrentTimeAction.rejected, (state, action) => {
        state.loading = false
      })
  }
})

export default CurrentTimeReducer.reducer
