import { createApi } from '@reduxjs/toolkit/query/react'
import { baseQuery } from '../common'
import {
  formDataToDataURL,
  getCreditDetails,
  handleProviderQueryError,
  transformProivderResponse,
  updateProviderQueryDataHelper,
  updateProvidersQueryData
} from './utils'

export const providersApi = createApi({
  reducerPath: 'providersApi',
  baseQuery,
  tagTypes: ['Providers', 'Provider', 'ProviderCredit'],
  endpoints: (builder) => ({
    getProviders: builder.query({
      query: ({ offset, limit, search, provider_type, status }) => {
        let endpoint = `/provider-booking?ordering=-date_joined&offset=${offset}&limit=${limit}`
        if (search) {
          endpoint += `&search=${search}`
        }

        if (provider_type !== '[]' && provider_type !== undefined) {
          endpoint += `&provider_type=${provider_type}`
        }

        if (status) {
          endpoint += `&status=${status}`
        }
        return { url: endpoint, method: 'GET' }
      },
      providesTags: ['Providers'],
      keepUnusedDataFor: 300
    }),
    getProvider: builder.query({
      query: (id) => {
        const endpoint = `/providers/${id}`
        return { url: endpoint, method: 'GET' }
      },
      onQueryStarted: () => {},

      providesTags: ['Provider'],
      transformResponse: (data) => {
        return transformProivderResponse(data)
      },
      keepUnusedDataFor: 300
    }),
    updateProvider: builder.mutation({
      query: ({ id, data }) => {
        const endpoint = `/providers/${id}`
        return { url: endpoint, method: 'PATCH', data }
      },
      async onQueryStarted({ id, ...patch }, { dispatch, queryFulfilled }) {
        const { patchResult, updateProviders } = updateProviderQueryDataHelper(
          dispatch,
          id,
          (draft) => {
            Object.assign(draft, patch.data)
          }
        )
        handleProviderQueryError(() => {
          patchResult.undo()
          updateProviders.undo()
          patchProviderCredit.undo()
        })(queryFulfilled)
      }
    }),
    bulkDeleteProviders: builder.mutation({
      query: ({ ids }) => ({
        url: '/providers/bulk-delete',
        method: 'POST',
        data: { ids }
      }),
      onQueryStarted: () => {},
      invalidatesTags: ['Providers']
    }),
    updateProviderImg: builder.mutation({
      query: ({ id, data }) => ({
        url: `/users/${id}/image/change`,
        method: 'PATCH',
        data
      }),
      async onQueryStarted({ id, ...patch }, { dispatch, queryFulfilled }) {
        const avatar = await formDataToDataURL(patch.data)
        const { patchResult, updateProviders } = updateProviderQueryDataHelper(
          dispatch,
          id,
          (draft) => {
            Object.assign(draft, { avatar })
          }
        )
        handleProviderQueryError(() => {
          patchResult.undo()
          updateProviders.undo()
        })(queryFulfilled)
      }
    }),
    getProviderCredit: builder.query({
      query: ({ id, promo }) => {
        if (id) {
          return {
            url: `/providers/${id}/${promo ? 'promo-credit' : 'ethera-credit'}`,
            method: 'GET'
          }
        }
      },
      providesTags: ['ProviderCredit']
    }),
    providerCredit: builder.mutation({
      query: ({ id, promo, data }) => {
        return {
          url: `/providers/${id}/${promo ? 'promo' : 'ethera'}-credit`,
          method: 'POST',
          data
        }
      },
      async onQueryStarted(
        { id, promo, ...patch },
        { dispatch, queryFulfilled }
      ) {
        const { data } = patch
        const { creditKey, name, msg, symbol } = getCreditDetails(data, promo)
        const queryParams = { id, promo }
        const { patchResult, updateProviders } = updateProviderQueryDataHelper(
          dispatch,
          id,
          (draft) => {
            Object.assign(draft, {
              [creditKey]: eval(
                `${draft[creditKey]} ${symbol} ${data.amount}  `
              )
            })
          }
        )
        const patchProviderCredit = dispatch(
          providersApi.util.updateQueryData(
            'getProviderCredit',
            queryParams,
            (draft) => {
              draft.result.unshift({
                ...data,
                date: Math.floor(new Date().getTime() / 1000)
              })
            }
          )
        )
        handleProviderQueryError(
          () => {
            patchResult.undo()
            updateProviders.undo()
            patchProviderCredit.undo()
          },
          name,
          msg
        )(queryFulfilled)
      }
    }),
    bulkProviderCredit: builder.mutation({
      query: ({ data, promo }) => {
        return {
          url: `/${promo ? 'promo' : 'ethera'}-credit/bulk-create`,
          method: 'POST',
          data
        }
      },
      async onQueryStarted({ promo, ...patch }, { dispatch, queryFulfilled }) {
        const { data } = patch
        const { ids, ...rest } = data
        const { creditKey, name, msg, symbol } = getCreditDetails(rest, promo)
        const patchProvider = []
        const updateProviders = updateProvidersQueryData(dispatch, (draft) => {
          draft?.result?.forEach((item) =>
            ids.forEach((id) => {
              if (item.id === id) {
                patchProvider.push(
                  dispatch(
                    providersApi.util.updateQueryData(
                      'getProvider',
                      id,
                      (draft) =>
                        Object.assign(draft, {
                          [creditKey]: eval(
                            `${draft[creditKey]} ${symbol} ${data.amount}  `
                          )
                        })
                    )
                  )
                )
              }
            })
          )
        })
        handleProviderQueryError(
          () => {
            updateProviders.undo()
            patchProvider.forEach((patch) => patch.undo())
          },
          name,
          msg
        )(queryFulfilled)
      }
    }),
    addProvider: builder.mutation({
      query: ({ data }) => {
        return {
          url: `/providers`,
          method: 'POST',
          data
        }
      },
      async onQueryStarted({}, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          updateProvidersQueryData(dispatch, (draft) => {
            draft?.result.unshift(data)
            draft?.result.pop()
          })
        } catch {}
      }
    }),
    addProviderImage: builder.mutation({
      query: ({ id, image }) => ({
        url: `/users/${id}/image`,
        method: 'POST',
        data: image
      }),
      async onQueryStarted({ id, ...patch }, { dispatch, queryFulfilled }) {
        const { data } = await queryFulfilled

        const { patchResult, updateProviders } = updateProviderQueryDataHelper(
          dispatch,
          id,
          (draft) => {
            const newData = { ...draft }
            newData.avatar = data.avatar
            Object.assign(draft, newData)
          }
        )
        queryFulfilled.catch(() => {
          patchResult.undo()
          updateProviders.undo()
          patchProviderCredit.undo()
        })
      }
    }),
    deleteProviderImage: builder.mutation({
      query: (id) => ({
        url: `/users/${id}/image/delete`,
        method: 'DELETE'
      }),
      onQueryStarted: () => {},
      invalidatesTags: ['Provider']
    })
  })
})

export const {
  useGetProvidersQuery,
  useBulkDeleteProvidersMutation,
  useGetProviderQuery,
  useUpdateProviderMutation,
  useUpdateProviderImgMutation,
  useGetProviderCreditQuery,
  useProviderCreditMutation,
  useBulkProviderCreditMutation,
  useAddProviderMutation,
  useAddProviderImageMutation,
  useDeleteProviderImageMutation,
  usePrefetch
} = providersApi
