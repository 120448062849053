// export const MAIN_URL = 'http://192.168.1.7:8000'
// export const MAIN_URL = 'https://backend-dot-konav2-dev.wl.r.appspot.com' // development URL

// export const MAIN_URL = 'https://konav2.wl.r.appspot.com' // Live URL

// export const MAIN_URL = "https://backend-dot-konav2.wl.r.appspot.com" // Staging URL

export const MAIN_URL = process.env.REACT_APP_SERVER // Staging URL

export const licenseTypesList = [
  {
    label: 'LP',
    value: '1'
  },
  {
    label: 'LMFT',
    value: '2'
  },
  {
    label: 'AMFT',
    value: '3'
  },
  {
    label: 'LCSW',
    value: '4'
  },
  {
    label: 'ACSW',
    value: '5'
  },
  {
    label: 'LPCC',
    value: '6'
  },
  {
    label: 'APCC',
    value: '7'
  },
  {
    label: 'LEP',
    value: '8'
  },
  {
    label: 'EP',
    value: '9'
  },
  {
    label: 'NP',
    value: '10'
  },
  {
    label: 'Psychiatrist',
    value: '11'
  },
  {
    label: 'MD',
    value: '12'
  },
  {
    label: 'OD',
    value: '13'
  },
  {
    label: 'BCBA',
    value: '14'
  },
  {
    label: 'RDN',
    value: '15'
  },
  {
    label: 'Acupuncturist',
    value: '16'
  },
  {
    label: 'RN',
    value: '17'
  },
  {
    label: 'Hypnotherapist',
    value: 20
  },
  {
    label: 'None',
    value: 21
  },
  {
    label: 'Other',
    value: 22
  }
]
