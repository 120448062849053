/* eslint-disable no-unused-vars */
import PropTypes from 'prop-types'
import ReactPaginate from 'react-paginate'
import { Col, Input, Label, Row } from 'reactstrap'
import {useSkin} from '@hooks/useSkin'
function CustomPagination({
  limit,
  handleLimit,
  currentPage,
  count,
  handlePagination
}) {
  const {skin} = useSkin()
  return (
    <div className="main-box-pagination">
      <div
        style={{
          position: 'fixed',
          bottom: '-2px',
          width: '-webkit-fill-available',
          backgroundColor: skin === 'dark' ? 'transparent' : '#fff'
        }}
      >
        <Row className=" mlr-1rem justify-content-between align-items-center sm-screen-d-block">
          <Col xs={4} sm={4} md={3} lg={2}>
            <div className="d-flex align-items-center py-1">
              <Label className="mr-1rem f-bold" for="sort-select">
                Show
              </Label>
              <Input
                bsSize="md"
                type="select"
                id="sort-select"
                value={limit}
                className="dataTable-select mx-wd-130px pointer skin-change radius-25"
                onChange={(e) => handleLimit(e.target.value)}
              >
                <option value={5}>5 items</option>
                <option value={10}>10 items</option>
                <option value={20}>20 items</option>
                <option value={50}>50 items</option>
                <option value={100}>100 items</option>
              </Input>
            </div>
          </Col>

          <Col xs={8} sm={8} md={9} lg={8} className="col-pr-1rem">
            <ReactPaginate
              previousLabel={''}
              nextLabel={''}
              forcePage={currentPage}
              breakLabel={'...'}
              pageRangeDisplayed={1}
              marginPagesDisplayed={1}
              activeClassName="active"
              pageClassName="page-item"
              breakClassName="page-item"
              nextLinkClassName="page-link"
              pageLinkClassName="page-link"
              breakLinkClassName="page-link"
              previousLinkClassName="page-link"
              nextClassName="page-item next-item"
              previousClassName="page-item prev-item"
              onPageChange={(data) => handlePagination(data)}
              pageCount={Math.ceil(count / limit) || 2}
              containerClassName={
                'pagination react-paginate separated-pagination pagination-sm justify-content-end pe-1 mt-1'
              }
            />
          </Col>
        </Row>
      </div>
    </div>
  )
}

CustomPagination.propTypes = {
  limit: PropTypes.number,
  currentPage: PropTypes.number,
  count: PropTypes.number,
  handleLimit: PropTypes.func,
  handlePagination: PropTypes.func
}

export default CustomPagination
