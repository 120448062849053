/* eslint-disable no-unused-vars */
import useJwt from '@src/auth/jwt/useJwt'
import { createSlice } from '@reduxjs/toolkit'

import { ResetPasswordAction, forgotPasswordAction, login } from './authAction'

const initialUser = () => {
  const item = useJwt.getUserData()
  return item ? item : {}
}

// Reducers
export const AuthReducer = createSlice({
  name: 'auth',
  initialState: {
    loginInProgress: false,
    forgotPasswordLoading: false,
    resetPasswordLoading: false,
    user: initialUser()
  },
  reducers: {
    handleLogout: (state) => {
      state.user = {}
      localStorage.clear()
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(login.pending, (state) => {
        state.loginInProgress = true
      })
      .addCase(login.fulfilled, (state, action) => {
        state.loginInProgress = false
        state.error = null
        state.user = action.payload
      })
      .addCase(login.rejected, (state, action) => {
        state.loginInProgress = false
        state.error = action.payload
        state.user = null
      })

      //**  forgot Password   */
      .addCase(forgotPasswordAction.pending, (state) => {
        state.forgotPasswordLoading = true
      })
      .addCase(forgotPasswordAction.fulfilled, (state, action) => {
        state.forgotPasswordLoading = false
        state.error = null
      })
      .addCase(forgotPasswordAction.rejected, (state, action) => {
        state.forgotPasswordLoading = false
        state.error = action.payload
      })

      /**  Reset Password   */
      .addCase(ResetPasswordAction.pending, (state) => {
        state.resetPasswordLoading = true
      })
      .addCase(ResetPasswordAction.fulfilled, (state, action) => {
        state.resetPasswordLoading = false
        state.error = null
      })
      .addCase(ResetPasswordAction.rejected, (state, action) => {
        state.resetPasswordLoading = false
        state.error = action.payload
      })
  }
})

export const { handleLogout } = AuthReducer.actions

export default AuthReducer.reducer
