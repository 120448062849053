/* eslint-disable no-unused-vars */
import React, { useMemo, useState } from 'react'

// ** components
import { ToastContent } from '@src/components/toast'
import { Check, X } from 'react-feather'
import toast from 'react-hot-toast'
// ** Third Party Packages
import { Icon } from '@iconify/react'
import moment from 'moment'
import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes'
import { Button, Spinner } from 'reactstrap'
import ExportModal from '../../../exportModal'

// ** Components
import FormIconField from '@FormIconField'
import SelectField from '@select'
import { BillingStatusObj } from '@status/BadgeColors'
import { ProviderType } from '@status/ProviderTypes'

// ** CSV Export
import { CSVLink } from 'react-csv'
import { licenseTypesList } from '../../../../constants'
import { useSkin } from '@hooks/useSkin'

function BillingHeader(props) {
  const {
    rows,
    status,
    search,
    statusList,
    loading,
    provider_type,
    onChangeHandler,
    onSearchChange,
    onChangeType,
    setSelectedValue,
    selectedValue,
    handleReset
  } = props
  const { skin } = useSkin()
  const [loadingExportFile, setLoadingExportFile] = useState(false)
  const today = new Date()
  const [openModal, setOpenModal] = useState(false)

  const headers = [
    { label: 'Date', key: 'date' },
    { label: 'Invoice Number', key: 'invoice_number' },
    { label: 'Provider', key: 'provider' },
    { label: 'Provider Type', key: 'provider_type' },
    { label: 'Status', key: 'status' },
    { label: 'Total', key: 'total' }
  ]

  const data = useMemo(() => {
    return rows.map((row, i) => {
      return {
        date:
          row?.created_at !== undefined
            ? moment.unix(row?.created_at).format('MMMM, YYYY')
            : '--',
        invoice_number: `# ${row?.invoice_number || '--'}`,
        provider: `${row?.provider?.first_name || '--'} ${
          row?.provider?.last_name || '--'
        }`,
        provider_type: row?.provider?.provider_license.map(
          (license) => ProviderType[license?.license_type]?.text || '--'
        ),
        status: BillingStatusObj[row?.status]?.label || '--',
        total: `$ ${row?.total_amount || 0}`
      }
    })
  }, [rows])

  const handleOpenModal = () => {
    setOpenModal(true)
  }
  const handleCloseModal = () => {
    setOpenModal(false)
  }
  return (
    <div className="pt-3 p-2">
      <div className="billing--header">
        <div className="billing--header__left">
          <SelectField
            header
            search={false}
            controlMinWidth="180px"
            className="billing--header__left--select-field"
            wd="100%"
            value={
              status?.value
                ? {
                    text: status?.text,
                    value: status?.value
                  }
                : ''
            }
            data={statusList}
            placeholder="Status"
            onChange={(e) => onChangeHandler('status', e)}
          />

          <section className={'p-l-7'}>
            <ReactMultiSelectCheckboxes
              hideSearch
              onChange={(e) => onChangeType(e)}
              className="multiValue-select"
              placeholderButtonLabel="Provider Type"
              options={licenseTypesList}
              setState={setSelectedValue}
              value={licenseTypesList?.filter((obj) =>
                selectedValue?.includes(obj.value)
              )}
            />
          </section>
        </div>

        <div className="billing--header__right">
          <FormIconField
            id="Search"
            name="search"
            maxLength={25}
            iconsName="ant-design:search-outlined"
            onChange={(e) => onSearchChange(e)}
            defaultValue={search}
            className="input-group-merge billing--header__right__search"
            inputClassName="input-control skin-change"
            iconClassName="icon-control skin-change"
          />
          <div className="group-button-headers">
            <Button
              disabled={
                status === null && search === '' && selectedValue?.length === 0
              }
              className="button-reset pd-s whiteSpace "
              onClick={() => {
                handleReset()
              }}
            >
              <Icon icon="fa6-solid:arrows-rotate" width="16" height="16" />
              <span className="pl-1">Reset</span>
            </Button>
            <Button
              size="sm "
              disabled={loadingExportFile}
              className="fs-x-small button-white pd-s whiteSpace"
              // onClick={exportInvoiceFileHandler}
              onClick={handleOpenModal}
            >
              {loadingExportFile ? (
                <Spinner size="sm" />
              ) : (
                <Icon icon="dashicons:upload" width="15" height="15" />
              )}
              <span className="ml-5px">Export</span>
            </Button>
            {/* {!rows?.length || loading ? (
              <Button
                size="sm "
                className="fs-x-small button-white pd-s whiteSpace"
                disabled={!rows?.length || loading}
              >
                <Icon icon="dashicons:upload" width="15" height="15" />
                <span className="ml-5px">Export</span>
              </Button>
            ) : (
              <Button
                size="sm "
                className="fs-x-small button-white pd-s whiteSpace"
              >
                <CSVLink
                  data={data}
                  headers={headers}
                  filename={`billing-list-${today}.csv`}
                  className={`text-decoration-none ${skin === 'dark' ? "white-color" : "black-color"}`}
                >
                  <Icon icon="dashicons:upload" width="15" height="15" />
                  <span className="ml-5px">Export</span>
                </CSVLink>
              </Button>
            )} */}
          </div>
          {openModal && (
            <ExportModal open={openModal} handleCloseModal={handleCloseModal} />
          )}
        </div>
      </div>
    </div>
  )
}

export default BillingHeader
