import { getDurations } from '@utils'
import moment from 'moment'

export function getCurrentWeekDates() {
  // Set Monday as the first day of the week
  moment.updateLocale('en', {
    week: {
      dow: 1 // Monday
    }
  })
  // Set the start of the week to Monday
  const startOfWeek = moment().startOf('isoWeek')
  // Set the end of the week to Sunday
  const endOfWeek = moment().endOf('isoWeek')
  // Format the dates
  const startOfWeekFormatted = startOfWeek.format('YYYY-MM-DD')
  const endOfWeekFormatted = endOfWeek.format('YYYY-MM-DD')
  // Return the start and end dates
  return {
    startDate: startOfWeekFormatted,
    endDate: endOfWeekFormatted
  }
}
export function checkDateRange(startDate, endDate, currentStart, currentEnd) {
  const start = moment(startDate)
  const end = moment(endDate)
  const currentStartDate = moment(currentStart)
  const currentEndDate = moment(currentEnd)

  return currentStartDate.isBefore(start) || currentEndDate.isAfter(end)
}
const getEvents = (rows) => {
  const bookingsWithoutCancelled = rows?.filter((element) => {
    if (element.status === 2) {
      return false
    } else if (element.status === 4) {
      return false
    } else {
      return true
    }
  })
  if (bookingsWithoutCancelled.length > 0) {
    return bookingsWithoutCancelled.map((row) => {
      return {
        allDay: false,
        url: '',
        start: `${row?.start_date__date}T${row?.start_time}`,
        end: `${row?.start_date__date}T${row?.end_time}`,
        title: `${row?.provider__first_name} ${row?.provider__last_name}`,
        resourceId: row?.room__id,
        isRoomSelected: true,
        extendedProps: {
          id: row?.id,
          date: row?.start_date__date,
          calendar: 'Ethera OC',
          img: row?.provider__avatar,
          end_time: row?.end_time || '--',
          start_time: row?.start_time || '--',
          duration: `${row?.duration / 3600} hours`,
          status: row?.status
        }
      }
    })
  }
  return []
}
const getDurationEvents = (rows, isRoomSelected) => {
  const durationEvents = []
  const bookingsWithoutCancelled = rows?.filter((element) => {
    if (element.status === 2) {
      return false
    } else if (element.status === 4) {
      return false
    } else {
      return true
    }
  })
  if (bookingsWithoutCancelled.length > 0) {
    const durations = getDurations(bookingsWithoutCancelled)
    durations.map((durationObj, index) => {
      const arr = Object.entries(durationObj).map(([key, value]) => ({
        date: key,
        duration: value
      }))
      arr.forEach((item) => {
        let sum = 0
        const duration = item.duration

        duration.map(function (dur) {
          sum += parseInt(dur)
        })
        durationEvents.push({
          allDay: true,
          url: '',
          id: `${index}`,
          start: `${arr[0].date}`,
          end: `${arr[0].date}`,
          isRoomSelected,
          title: `${sum / 3600}`,
          extendedProps: {
            start: `${arr[0].date}`,
            end: `${arr[0].date}`,
            status: arr[0].status,
            calendar: 'Ethera OC'
          }
        })
      })
    })
  }
  return durationEvents
}
export const generateEvents = (rows, view, room) => {
  return rows
    ? view === 'resourceTimeGridDay' || room?.value
      ? getEvents(rows)
      : view === 'timeGridWeek' || view === 'dayGridMonth' || !room?.value
      ? getDurationEvents(rows, !!room?.value)
      : []
    : []
}
