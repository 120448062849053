import logo from '@src/assets/images/logo/logo.png'

const ExportSpinner = () => {
  return (
    <div className="fallback-spinner-2 d-flex flex-column justify-content-center">
      <img
        className="fallback-logo"
        src={logo}
        alt="logo"
        style={{ width: '100px', height: '100px', marginBottom: '25px' }}
      />
      <div className="loading">
        <div className="effect-1 effects"></div>
        <div className="effect-2 effects"></div>
        <div className="effect-3 effects"></div>
      </div>
    </div>
  )
}

export default ExportSpinner
