/* eslint-disable no-unused-vars */
// ** React Imports
import { Link } from 'react-router-dom'

// ** Custom Components
import Avatar from '@components/avatar'
import defaultAvatar from '@src/assets/default.png'

// ** Third Party Components
import { Icon } from '@iconify/react'
import { useDispatch, useSelector } from 'react-redux'

// ** Reactstrap Imports
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown
} from 'reactstrap'

import { handleLogout } from '@store/authentication/authSlice'
const UserDropdown = () => {
  const dispatch = useDispatch()
  const { getAllAdminsData } = useSelector((state) => state.system)
  const all_admin_data = getAllAdminsData?.adminsList
  const adminAvatar = useSelector((state) => state.auth?.user?.avatar)
  const admin_id = useSelector((state) => state.auth?.user?.user_id)
  const filter_admin =
    !!all_admin_data?.length &&
    all_admin_data?.find((data) => data.id === admin_id)
  const admin_image = filter_admin?.avatar
  const profile_avatar = localStorage.getItem('avatar')

  return (
    <>
      <UncontrolledDropdown tag="li" className="dropdown-user nav-item">
        <DropdownToggle
          href="/"
          tag="a"
          className="nav-link dropdown-user-link"
          onClick={(e) => e.preventDefault()}
        >
          <div className="user-nav d-sm-flex d-none">
          </div>
          {!!admin_image ? (
            <Avatar
              img={profile_avatar || admin_image || defaultAvatar}
              imgHeight="40"
              imgWidth="40"
            />
          ) : (
            <Avatar
              img={profile_avatar || adminAvatar || defaultAvatar}
              imgHeight="40"
              imgWidth="40"
            />
          )}
        </DropdownToggle>
        <DropdownMenu end>
          <DropdownItem
            tag={Link}
            to="/login"
            onClick={() => {
              dispatch(handleLogout())
            }}
          >
            <Icon icon="entypo:log-out" className="me-75" />
            <span className="align-middle">
              <strong>Logout</strong>
            </span>
          </DropdownItem>
        </DropdownMenu>
      </UncontrolledDropdown>
    </>
  )
}

export default UserDropdown
