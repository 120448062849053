import { MAIN_URL } from '@src/constants'
import axios from 'axios'
import { getToken } from '../../utility/Utils'
export const prepareHeaders = () => {
  const accessToken = getToken()
  if (!accessToken) {
    throw new Error('Authentication token is missing.')
  }

  const headers = {
    Authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0b2tlbl90eXBlIjoiYWNjZXNzIiwiZXhwIjoxNjkwODc1MzM1LCJpYXQiOjE2OTAyNzA1MzUsImp0aSI6IjU1NjI5ODI5YTNiZTRhYmQ5Mzg1NjU0YjQ4NzA2YzE3IiwidXNlcl9pZCI6ImRhNjIwYzA3LWVkMTctNGM1Yy1hZGVhLWI2Nzc5MzVhNTFiMiIsInVzZXJuYW1lIjoibS5hc2hpckBzcGFya2FpLmNhIiwiaXNfc3VwZXJ1c2VyIjp0cnVlLCJpc19zdGFmZiI6dHJ1ZSwiaXNfcHJvdmlkZXIiOmZhbHNlLCJpc19jbGllbnQiOmZhbHNlLCJlbWFpbCI6Im0uYXNoaXJAc3BhcmthaS5jYSIsImlzX2ZpcnN0X2xvZ2luIjp0cnVlLCJhdmF0YXIiOiJodHRwczovL3N0b3JhZ2UuZ29vZ2xlYXBpcy5jb20va29uYXYyLWRldi5hcHBzcG90LmNvbS9kYTYyMGMwNy1lZDE3LTRjNWMtYWRlYS1iNjc3OTM1YTUxYjIvMjAyMy0wNy0xNCUyMDA1JTNBMDclM0EyMy42NDM3MzQucG5nIiwiaXNfYWN0aXZlIjp0cnVlLCJmaXJzdF9uYW1lIjoiQXNoaXIiLCJtaWRkbGVfbmFtZSI6bnVsbCwibGFzdF9uYW1lIjoiQWxpIiwicGhvbmVfbnVtYmVyIjpudWxsfQ.6xDj0aW6-LmY7RubD2_PCIeo85kh1aaAD1e8Go0FuVAI`,
    'Content-type': 'application/json'
  }

  return headers
}
const axiosBaseQuery =
  ({ baseUrl } = { baseUrl: '' }) =>
  async ({ url, method, data, params }) => {
    try {
      const result = await axios({
        url: baseUrl + url,
        method,
        data,
        params
      })

      return { data: result.data }
    } catch (axiosError) {
      return {
        error: {
          status: axiosError.response?.status,
          data: axiosError.response?.data || axiosError.message
        }
      }
    }
  }
export const baseQuery = axiosBaseQuery({ baseUrl: `${MAIN_URL}/api` })
