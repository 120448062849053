/* eslint-disable no-unused-vars */
import { Icon } from '@iconify/react'
import { components } from 'react-select'
// hooks
import useMediaQuery from '@hooks/useMediaQuery'
import { useSkin } from '@hooks/useSkin'

export const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      {props.selectProps?.menuIsOpen ? (
        <Icon icon="fe:arrow-up" />
      ) : (
        <Icon icon="bxs:chevron-down" />
      )}
    </components.DropdownIndicator>
  )
}

export const customStyles = (
  header,
  wd,
  menuHeight,
  controlMinWidth,
  controlMaxWidth,
  defaultWidth,
  formikError
) => {
  const { skin } = useSkin()
  const small = useMediaQuery('(max-width: 1000px)')

  return {
    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1
      const transition = 'opacity 300ms'

      return { ...provided, opacity, transition }
    },
    container: (provider) => ({
      ...provider,
      width: wd && `${wd}`,
      marginLeft: header && '5px 0px',
      marginRight: header && '0px',
      marginBottom: small || !header ? '1rem' : '0px'
    }),
    input: (provider) => ({
      ...provider,
      margin: header && 0,
      padding: header && 0
    }),
    valueContainer: (provider) => ({
      ...provider,
      padding: !header && '0.587rem 0rem',
      paddingTop: header && '0 !important',
      paddingBottom: header && '0 !important'
    }),
    control: (provided, state) => ({
      ...provided,
      minWidth: `${controlMinWidth}`,
      maxWidth: `${controlMaxWidth}`,
      minHeight: header && '30px',
      borderWidth: '1px',
      borderColor: formikError
        ? '#ea5455 !important'
        : skin === 'light'
        ? '#d8d6de'
        : '#fefefe',
      borderColor: skin === 'light' ? '#d8d6de' : '#fefefe',
      fontSize: 13,

      backgroundColor: state.isDisabled
        ? '#efefef'
        : skin === 'light'
        ? 'white'
        : '#323a50',
      outline: 'none',
      borderRadius: state.selectProps.menuIsOpen ? 0 : '20px',
      borderTopRightRadius: state.selectProps.menuIsOpen ? '10px' : '20px',
      borderTopLeftRadius: state.selectProps.menuIsOpen ? '10px' : '20px',
      padding: '0 0.5rem ',
      boxShadow: state.isFocused ? 0 : 0,

      '&:hover': {
        cursor: 'pointer',
        boxShadow: state.isFocused ? 0 : 0
      }
    }),
    singleValue: (provided) => ({
      ...provided,
      color: skin === 'light' ? '#6e6b7b' : '#fff'
    }),

    menu: (provided) => ({
      ...provided,
      width: '-webkit-fill-available',
      minWidth: '140px',
      backgroundColor: skin === 'light' ? '#fefefe' : '#343d55',
      marginTop: 0,
      borderRadius: 0,
      boxShadow:
        '0 0 0 0px hsla(0, 0%, 0%, 0.1), 0 4px 11px hsla(0, 0%, 0%, 0.1)',
      borderBottomRightRadius: '10px',
      borderBottomLeftRadius: '10px'
    }),
    menuList: (provided) => {
      return {
        ...provided,
        margin: '0px 0px',
        padding: '10px',

        maxHeight: menuHeight
      }
    },
    placeholder: (provided) => ({
      ...provided,
      color: '#A9A9A9'
    }),
    option: (provided, state) => ({
      ...provided,
      borderRadius: '24px',
      marginBottom: '5px',
      marginTop: '1px',
      padding: '1px 15px !important',
      minWidth: !defaultWidth && '100px !important',

      backgroundColor:
        skin === 'light' && state.isSelected
          ? '#fefbf4'
          : state.isSelected && '#0c102a',
      border:
        skin === 'light' && state.isSelected
          ? '1px solid #783e34'
          : state.isSelected && '1px solid #fff',
      color: skin === 'light' ? '#363b40' : 'white',

      '&:hover': {
        cursor: 'pointer',
        backgroundColor: skin === 'light' ? '#fefbf4' : '#0c102a'
      }
    })
  }
}
