// ** Redux Imports
import { configureStore } from '@reduxjs/toolkit'

// ** Reducers
import { providersApi } from '../services/providers/providersApi'
import { calendarApi } from '../services/calendar/calendarApi'
import AccessReducer from './access/accessSlice'
import AuthReducer from './authentication/authSlice'
import BillingReducer from './billing/billingSlice'
import BookingsReducer from './booking/bookingSlice'
import CalendarReducer from './calendar/calendarSlice'
import layout from './layout'
import LocationsReducer from './locations/locationsSlice'
import navbar from './navbar'
import ProviderReducer from './provider/providerSlice'
import RoomsReducer from './rooms/roomsSlice'
import SystemReducer from './system/systemSlice'
import TiersReducer from './tiers/tiersSlice'
import CurrentTimeReducer from './getCurrentTime/getCurrentTimeSlice'
const store = configureStore({
  reducer: {
    navbar,
    layout,
    auth: AuthReducer,
    provider: ProviderReducer,
    locations: LocationsReducer,
    access: AccessReducer,
    tiers: TiersReducer,
    rooms: RoomsReducer,
    booking: BookingsReducer,
    billing: BillingReducer,
    system: SystemReducer,
    calendar: CalendarReducer,
    currentTime: CurrentTimeReducer,
    [providersApi.reducerPath]: providersApi.reducer,
    [calendarApi.reducerPath]: calendarApi.reducer
  },
  middleware: (getDefaultMiddleware) => {
    const customMiddleware = [providersApi.middleware, calendarApi.middleware]
    return getDefaultMiddleware({
      serializableCheck: false
    }).concat(customMiddleware)
  }
})

export { store }
