export const PAGE_SIZE = 10
export const PROVIDERS_PARAMS = 'providers_params'
export const StatusList = [
  {
    text: 'Pending',
    value: 1
  },
  {
    text: 'Active',
    value: 2
  },
  {
    text: 'Inactive',
    value: 3
  }
]
