/* eslint-disable no-unused-vars */
import { useEffect } from 'react'

function useScrollBar({open}) {
  useEffect(() => {
    let isMounted = true
    if (isMounted && open) {
      document.body.style.overflow = 'hidden'
      document.body.style.paddingRight = '0px'

    } else {
      document.body.style.overflow = 'auto'
    }
    return () => {
      isMounted = false
      document.body.style.overflow = 'auto'
    }
  }, [open])
}

export default useScrollBar
