export const ProviderType = {
  1: { text: 'LP', value: 1 },
  2: { text: 'LMFT', value: 2 },
  3: { text: 'AMFT', value: 3 },
  4: { text: 'LCSW', value: 4 },
  5: { text: 'ACSW', value: 5 },
  6: { text: 'LPCC', value: 6 },
  7: { text: 'APCC', value: 7 },
  8: { text: 'LEP', value: 8 },
  9: { text: 'EP', value: 9 },
  10: { text: 'NP', value: 10 },
  11: { text: 'Psychiatrist', value: 11 },
  12: { text: 'MD', value: 12 },
  13: { text: 'OD', value: 13 },
  14: { text: 'BCBA', value: 14 },
  15: { text: 'RDN', value: 15 },
  16: { text: 'Acupuncturist', value: 16 },
  17: { text: 'RN', value: 17 },
  19: { text: 'Life Coach', value: 19 },
  20: { text: 'Hypnotherapist', value: 20 },
  21: { text: 'None', value: 21 },
  22: { text: 'Other', value: 22 }
}

export const Status = {
  1: { text: 'ACTIVE', value: 1 },
  2: { text: 'INACTIVE', value: 2 },
  3: { text: 'PENDING', value: 3 },
  4: { text: 'SUSPENDED', value: 4 },
  5: { text: 'DELETED', value: 5 },
  6: { text: 'NONE', value: 6 }
}
